import { useSelector } from 'react-redux';

export default function useACL(url = '', type = 'write') {
	const { acl = {} } = useSelector((state) => state.model);
	let scopes = acl?.scopes;

	if (type === 'read' && scopes?.[url]?.R) {
		return true;
	}

	if (scopes?.[url]?.W) {
		return true;
	}

	return false;
}
